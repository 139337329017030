$(function() {
    if (getCookie('cookielaw') !== 'yes') {
        $('body').append('<div id="cookie-prompt" style="position: fixed; bottom:0; padding:10px 20px; z-index:20" class="alert-warning"><button type="button" class="close" id="cookie-close" aria-label="Zamknij"><span aria-hidden="true">x</span></button>Ta strona zapisuje w Twoim urządzeniu krótkie informacje tekstowe zwane plikami cookies (ciasteczkami). Są one wykorzystywane do zapisywania indywidualnych preferencji użytkownika, umożliwiają logowanie się do serwisu, pomagają w zbieraniu statystyk Twojej aktywności na stronie. W każdej chwili możesz zablokować lub ograniczyć umieszczanie plików cookies (ciasteczek) w Twoim urządzeniu zmieniając ustawienia przeglądarki internetowej. Ustawienie lub pozostawienie ustawienia przeglądarki na akceptację cookies (ciasteczek) oznacza wyrażenie przez Ciebie świadomej zgody na takie praktyki. Więcej o możliwościach zmiany ustawień przeglądarki internetowej znajdziesz <a target="_blank" href="http://www.jak-zablokowac-cookies.pl" class="alert-link" rel="nofollow" title="Zmiana ustawień cookies (ciasteczek)">tutaj</a>.</div>');
    }
    $('#cookie-close').on('click', function() {
        var date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
        document.cookie = 'cookielaw=yes' + expires;
        $('#cookie-prompt').remove();
    });

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    }

    $('[data-toggle="tooltip"]').tooltip();

    $('div.alert-info').delay(10000).slideUp(300);
    $('div.alert-danger').delay(10000).slideUp(300);
    var first = 0;
    var third = 3;
    var carouselIndicators = 0;
    var indicatorIndex = 0;
    var currentIndicatorIndex = 0;
    var newsTotalNumber = $('.item').length;
    if (newsTotalNumber % 3 === 0) {
        carouselIndicators = newsTotalNumber / 3 - 1;
    } else {
        carouselIndicators = Math.floor(newsTotalNumber / 3);
    }
    for (i = 0; i <= carouselIndicators; i++) {
        if (i == 0) {
            $('.carousel-indicators').append('<li data-current="' + i + '" class="dot active"></li>');
            continue;
        }
        $('.carousel-indicators').append('<li data-current="' + i + '" class="dot"></li>');
    }

    $('.item').slice(first, third).addClass('active');
    $('#next').css('display', 'none');
    if (newsTotalNumber < 4) {
        $('#prev').css('display', 'none');
        $('.carousel-indicators').css('display', 'none');
    }

    $('#prev').on('click', function() {
        $('.item').slice(first, third).removeClass('active');
        $('li.dot').eq(first / 3).removeClass('active');
        first = first + 3;
        third = third + 3;
        $('li.dot').eq(first / 3).addClass('active');
        $('.item').slice(first, third).addClass('active');
        if ($('.item:last').hasClass('active')) {
            $('#prev').css('display', 'none');
            $('#next').css('display', 'initial');
        } else {
            $('#prev').css('display', 'initial');
            $('#next').css('display', 'initial');
        }
        currentIndicatorIndex = $('.dot.active').data('current');
    });
    $('#next').on('click', function() {
        $('.item').slice(first, third).removeClass('active');
        $('li.dot').eq(first / 3).removeClass('active');
        first = first - 3;
        third = third - 3;
        $('li.dot').eq(first / 3).addClass('active');
        $('.item').slice(first, third).addClass('active');
        if ($('.item:first').hasClass('active')) {
            $('#prev').css('display', 'initial');
            $('#next').css('display', 'none');
        } else {
            $('#next').css('display', 'initial');
            $('#prev').css('display', 'initial');
        }
        currentIndicatorIndex = $('.dot.active').data('current');
    });
    $('li.dot').on('click', function(e) {
        indicatorIndex = $(e.target).data('current');
        $('.item').slice(first, third).removeClass('active');
        if (indicatorIndex != currentIndicatorIndex) {
            $('li.dot').eq(currentIndicatorIndex).removeClass('active');
            first = first - (currentIndicatorIndex - indicatorIndex) * 3;
            third = third - (currentIndicatorIndex - indicatorIndex) * 3;
            $('.item').slice(first, third).addClass('active');
            $('li.dot').eq(indicatorIndex).addClass('active');
            currentIndicatorIndex = indicatorIndex;
        }
        if (indicatorIndex == currentIndicatorIndex) {
            $('.item').slice(first, third).addClass('active');
            $('li.dot').eq(indicatorIndex).addClass('active');
            currentIndicatorIndex = indicatorIndex;
        }
        if (indicatorIndex == 0) {
            $('#next').css('display', 'none');
            $('#prev').css('display', 'initial');
        } else if (indicatorIndex == carouselIndicators) {
            $('#prev').css('display', 'none');
            $('#next').css('display', 'initial');
        } else {
            $('#next').css('display', 'initial');
            $('#prev').css('display', 'initial');
        }
    });

});

$(window).load(function() {
    $('.carousel .carousel-inner').find('img').css({
        'width': 'auto',
        'max-height': '150px',
        'display': 'block'
    });
    $('.news-content').css({
        'height': '150px',
        'overflow': 'hidden',
        'word-wrap': 'break-word'
    });
    var newsRowHeight = $('.carousel .carousel-inner').height();
    $('.carousel .panel-body').css('height', newsRowHeight);

});
